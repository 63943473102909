import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/[lng]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcher"] */ "/vercel/path0/components/language-switcher/language-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainNav"] */ "/vercel/path0/components/main-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/vercel/path0/components/theme-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"lib/fonts.ts\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
